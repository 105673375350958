import React, { useEffect, createContext, useState, useContext, useCallback, useRef, useMemo } from "react";
import { ByteBrew } from "bytebrew-web-sdk";
import { gameMapping, SupportedGames } from "@ugitgud/legos/components/util/UggGAEventProvider";

export type ByteBrewProviderProps = { newCustomEvent: typeof ByteBrew.newCustomEvent; initialized: boolean };

export const ByteBrewContext = createContext<ByteBrewProviderProps>({
  newCustomEvent: ByteBrew.newCustomEvent,
  initialized: false,
});

export const initByteBrew = ({ appId, appKey, appVersion }: { appId: string; appKey: string; appVersion: string }) => {
  if (typeof window !== undefined) {
    ByteBrew.initializeByteBrew(appId, appKey, appVersion);
  }
};

const ByteBrewInitializer: React.FC<{ appId: string; appKey: string; appVersion: string; onInit: () => void }> = ({
  appVersion,
  appId,
  appKey,
  onInit,
}) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      initByteBrew({
        appVersion: appVersion,
        appId: appId,
        appKey: appKey,
      });
      isMounted.current = true;
      onInit();
    }
  }, [appId, appKey, appVersion, onInit]);
  return null;
};

export const ByteBrewProvider: React.FC<React.PropsWithChildren<{ appId: string; appKey: string; appVersion: string }>> = ({
  children,
  ...rest
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const onInit = useCallback(() => setIsInitialized(true), [setIsInitialized]);

  return (
    <ByteBrewContext.Provider value={{ newCustomEvent: ByteBrew.newCustomEvent, initialized: isInitialized }}>
      <ByteBrewInitializer onInit={onInit} {...rest} />
      {children}
    </ByteBrewContext.Provider>
  );
};

export const useSwitchGame = () => {
  const { newCustomEvent } = useContext(ByteBrewContext);
  return useCallback(
    (value: SupportedGames) => {
      if (ByteBrew.isByteBrewInitialized()) {
        const game = gameMapping[value];
        if (game) {
          newCustomEvent("switch_game", game);
        } else {
          console.warn("Unknown game", value);
        }
      } else {
        console.warn("ByteBrew not initialized yet!");
      }
    },
    [newCustomEvent],
  );
};
